import React from 'react'

import DropdownItem from './DropdownItem'

export default class Dropdown extends React.Component {
  constructor() {
    super()

    this.wrapperRef = React.createRef()

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.setOption = this.setOption.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.props.actions.toggleDropdown()
    }
  }

  setOption(id) {
    this.props.actions.setOption(id)
  }

  renderDropdownItems() {
    let dropdownItems = []
    let action = this.setOption
    let {selectedID, withImage} = this.props

    this.props.options.map(function(option, i) {
      dropdownItems.push(
        <DropdownItem
          id       = {option.id}
          img      = {withImage ? option.userpic.publicURL : null}
          label    = {option.name}
          selected = {selectedID == option.id ? true : false}
          onClick  = {action}
          key      = {i}
        />
      )
    })

    return (
      dropdownItems
    )
  }

  render() {
    let activeClass = this.props.isOpen ? 'open' : ''

    return (
      <div className={`filterDropdown-сontainer ${activeClass}`}>
        <div
          ref       = {this.wrapperRef}
          className = 'filterDropdown'
        >
          {this.renderDropdownItems()}
        </div>
      </div>
    )
  }
}
