import React from 'react'

import ProjectMeta    from './ProjectMeta.js'
import VideoThumbnail from './VideoThumbnail.js'

export default class ProjectItem extends React.Component {
  constructor() {
    super()

    this.handleVideoThumbnailClick = this.handleVideoThumbnailClick.bind(this)
  }

  handleVideoThumbnailClick() {
    let data = {
      id: this.props.videoURL.split('?v=')[1],
      start: this.props.start
    }

    this.props.onClick(data)
  }

  renderBackgroundVideo() {
    const videoID = this.props.videoURL.split('?v=')[1]

    return (
      <video autoPlay muted loop playsInline>
        <source
          src    = {this.props.thumbnail.publicURL}
          poster = {`url(https://img.youtube.com/vi/${videoID}/maxresdefault.jpg)`}
          type   = 'video/mp4'
        />
      </video>
    )
  }

  renderClientLogo() {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: this.props.client.logo }}
        className = 'projectItemLogo'
      />
    )
  }

  render() {
    const kindClass = this.props.thumbnail ? 'emphasized' : 'default'

    return (
      <div
        className = {`projectItem-container ${kindClass}`}
        onClick   = {this.props.thumbnail ? this.handleVideoThumbnailClick : null}
      >
        {this.props.thumbnail ? this.renderBackgroundVideo() : ''}

        <div className='projectItem'>
          <div className='projectItem-content'>
            <div className='projectItem-description'>
              {this.props.client.logo ? this.renderClientLogo() : null}

              <h3>{this.props.description}</h3>
            </div>

            <ProjectMeta
              userpic    = {this.props.author.userpic.publicURL}
              authorName = {this.props.author.name}
              views      = {this.props.views}
            />
          </div>

          <VideoThumbnail
            onClick = {this.handleVideoThumbnailClick}
            videoID = {this.props.videoURL.split('?v=')[1]}
          />
        </div>
      </div>
    )
  }
}
