import React from 'react'

import XLarge       from '../../../assets/icons/XLarge.svg'
import ChevronLarge from '../../../assets/icons/ChevronLarge.svg'
import Dropdown     from './Dropdown'

export default class FilterItem extends React.Component {
  constructor() {
    super()

    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.setOption = this.setOption.bind(this)
    this.removeOption = this.removeOption.bind(this)

    this.state = {
      dropdownOpen: false
    }
  }

  toggleDropdown() {
    let actualState = this.state.dropdownOpen

    this.setState({
      dropdownOpen: !actualState
    })

    // Disable Scroll on Mobile
    if(typeof window !== undefined) {
      if(window.innerWidth <= 680) {  
        this.props.actions.togglePageScroll()
      }
    }
  }

  setOption(id) {
    let data = {
      property: this.props.property,
      value: id
    }

    this.props.actions.setFilter(data)
    this.toggleDropdown()
  }

  removeOption() {
    this.props.actions.removeFilter(this.props.property)
  }

  renderIcon() {
    if(this.props.selected.length != 0) {
      return (
        <img
          src       = {XLarge}
          alt       = 'icon'
          className = 'projectsFilterItemIcon'
          onClick   = {this.removeOption}
        />
      )
    } else {
      return (
        <img
          src       = {ChevronLarge}
          alt       = 'icon'
          className = {this.state.dropdownOpen ? 'projectsFilterItemIcon flip' : 'projectsFilterItemIcon'}
          onClick   = {this.toggleDropdown}
        />
      )
    }
  }

  renderImage() {
    if(this.props.selected.length != 0 && this.props.withImage) {
      return(
        <img
          src       = {this.props.selected.userpic.publicURL}
          alt       = 'userpic'
          className = 'projectsFilterItemImg'
          onClick   = {this.toggleDropdown}
        />
      )
    }
  }

  renderDropdown() {
    let actions = {
      setOption: this.setOption,
      toggleDropdown: this.toggleDropdown
    }

    if(this.state.dropdownOpen) {
      return(
        <Dropdown
          actions    = {actions}
          options    = {this.props.options}
          isOpen     = {this.state.dropdownOpen}
          selectedID = {this.props.selected.id}
          withImage  = {this.props.withImage}
        />
      )
    }
  }

  render() {
    let isActive = this.props.selected.length != 0

    let activeClass = isActive ? 'active' : ''
    let openClass = this.state.dropdownOpen ? 'open' : ''

    return (
      <div className={`projectsFilterItem ${activeClass} ${openClass}`}>
        {this.renderImage()}

        <h2 onClick={this.toggleDropdown}>
          {isActive ? this.props.selected.name : this.props.label}
        </h2>

        {this.renderIcon()}

        {this.renderDropdown()}
      </div>
    )
  }
}
