import React from 'react'
import { graphql, navigate } from 'gatsby'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import YouTube     from 'react-youtube'
import MetaData    from '../components/atoms/MetaData.js'
import ModalPlayer from '../components/atoms/ModalPlayer.js'
import FilterItem  from '../components/atoms/projectsFilter/FilterItem'
import ProjectItem from '../components/atoms/ProjectItem.js'
import MenuBar     from '../components/MenuBar'
import Footer      from '../components/Footer'

export default class Project extends React.Component {
  constructor() {
    super()

    this.setFilter = this.setFilter.bind(this)
    this.removeFilter = this.removeFilter.bind(this)
    this.toggleModalPlayer = this.toggleModalPlayer.bind(this)
    this.togglePageScroll = this.togglePageScroll.bind(this)

    this.targetElement = null

    this.state = {
      modalPlayer: {
        active: false,
        videoID: null
      },
      filters: {
        author: null,
        kind: null
      },
      isFetch: true,
      scroll: true
    }
  }

  componentDidMount() {
    this.setFiltersFromURL()
    this.targetElement = document.querySelector('.page')
  }

  componentDidUpdate(prevProps, prevState) {
    // Set Filter If URL Was Changed
    if(prevProps.location.state != this.props.location.state) {
      this.setFiltersFromURL()
    }

    // Disable Page Scroll
    if(prevState.scroll != this.state.scroll) {
      if(this.state.scroll) {
        enableBodyScroll(this.targetElement)
      } else {
        disableBodyScroll(this.targetElement)
      }
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  setFiltersFromURL() {
    let params       = new URLSearchParams(window.location.search)
    let paramsAuthor = params.get('author')
    let paramsKind   = params.get('kind')

    this.setState({
      ...this.state,
      filters: {
        author: paramsAuthor,
        kind: paramsKind
      },
      isFetch: false
    })
  }

  setFilter(data) {
    let params = new URLSearchParams(window.location.search)
    params.set(data.property.toString(), data.value)

    navigate(`/projects/?${params.toString()}`)
  }

  removeFilter(data) {
    let params = new URLSearchParams(window.location.search)
    params.delete(data.toString())

    navigate(`/projects/?${params.toString()}`)
  }

  toggleModalPlayer(data) {
    if(this.state.modalPlayer.active) {
      this.setState({
        ...this.state,
        modalPlayer: {
          active: false,
          videoID: null,
          start: null
        },
        scroll: true
      })
    } else {
      this.setState({
        ...this.state,
        modalPlayer: {
          active: true,
          videoID: data.id,
          start: data.start
        },
        scroll: false
      })
    }
  }

  togglePageScroll() {
    this.setState({
      ...this.state,
      scroll: !this.state.scroll
    })
  }

  renderModalPlayer() {
    return (
      <ModalPlayer
        videoID = {this.state.modalPlayer.videoID}
        start   = {this.state.modalPlayer.start}
        onClick = {this.toggleModalPlayer}
      />
    )
  }

  renderFiltersItems() {
    let authorsData = this.props.data.allAuthorsJson.nodes
    let kindsData   = this.props.data.allKindsJson.nodes
    let filtersData = this.state.filters

    let selectedKind   = []
    let selectedAuthor = []

    let actions = {
      setFilter: this.setFilter,
      removeFilter: this.removeFilter,
      togglePageScroll: this.togglePageScroll
    }

    // Set Authors Data
    if(filtersData.author) {
      selectedAuthor = authorsData.find(function(author) {
        if(author.id == filtersData.author)
          return true
      })
    }

    // Set Kinds Data
    if(filtersData.kind) {
      selectedKind = kindsData.find(function(kind) {
        if(kind.id == filtersData.kind)
          return true
      })
    }

    return(
      <div className='projectsFiltersList-container'>
        <FilterItem
          actions   = {actions}
          withImage = {false}
          label     = 'формат'
          options   = {kindsData}
          selected  = {selectedKind}
          property  = 'kind'
        />

        <FilterItem
          actions   = {actions}
          withImage = {true}
          label     = 'автор'
          options   = {authorsData}
          selected  = {selectedAuthor}
          property  = 'author'
        />
      </div>
    )
  }

  renderProjectsItems() {
    let projectsElements = []
    let sortedData
    let projectsData = this.props.data.allProjectsJson.nodes
    let filtersData  = this.state.filters
    let action       = this.toggleModalPlayer

    if(filtersData.author && filtersData.kind) {
      sortedData = projectsData.filter(function(project) {
        return project.author.id == filtersData.author && project.kind.id == filtersData.kind
      })
    } else if (filtersData.author || filtersData.kind) {
      sortedData = projectsData.filter(function(project) {
        return project.author.id == filtersData.author || project.kind.id == filtersData.kind
      })
    } else {
      sortedData = projectsData
    }

    sortedData.map(function(project, i) {
      projectsElements.push(
        <ProjectItem
          {...project}
          key     = { i }
          onClick = {action}
        />
      )
    })

    return projectsElements
  }

  render() {
    return(
      <div className='app-wrapper'>
        <MetaData
          title       = 'Wylsacom Media — Кейсы'
          description = 'Работаем с крупными брендами и рассказываем об их продуктах нашей аудитории.'
        />

        {this.state.modalPlayer.active ? this.renderModalPlayer() : null}

        <MenuBar
          items    = {MenuBarItems}
          internal = 'кейсы'
          action   = {this.togglePageScroll}
        />

        {this.state.isFetch ? '' : this.renderFiltersItems()}
        {this.state.isFetch ? '' : this.renderProjectsItems()}

        <Footer />
      </div>
    )
  }
}

export const ProjectsData = graphql`
  {
    allProjectsJson(sort: {fields: publishedAt, order: DESC}) {
      nodes {
        description
        videoURL
        start
        views
        thumbnail {
          publicURL
        }
        client {
          name
          color
          logo
        }
        author {
          id
          name
          userpic {
            publicURL
          }
        }
        kind {
          id
          name
        }
      }
    },
    allAuthorsJson(filter: {featured: {eq: true}}, sort: {fields: name}) {
      nodes {
        id
        name
        userpic {
          publicURL
        }
      }
    },
    allKindsJson(sort: {fields: name}) {
      nodes {
        id
        name
      }
    }
  }
`

const MenuBarItems = [
  {
    title: 'связаться',
    to: '#contact',
    external: false
  },
  {
    title: 'цены',
    to: 'https://docs.google.com/spreadsheets/d/1fOcAeps0CaBygkR9bmPinpkZ2LBYEzO_PJkYkfMKLrg',
    external: true
  }
]
