import React from 'react'

export default class DropdownItem extends React.Component {
  constructor() {
    super()

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.props.onClick(this.props.id)
  }

  renderImage() {
    if(this.props.img) {
      return(
        <img
          src       = {this.props.img}
          alt       = 'userpic'
          className = 'dropdownItemImg'
          onClick   = {this.toggleDropdown}
        />
      )
    }
  }

  render() {
    return (
      <div className='dropdownItem' onClick={this.handleClick}>
        {this.renderImage()}

        <span>
          {this.props.label}
        </span>
      </div>
    )
  }
}
